.slider {
  .swiper-pagination-bullets{
    left: 0;
    text-align: left;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 60px;

    @media (max-width: 480px) {
      display: none;
    }

    .swiper-pagination-bullet {
      margin-bottom: 56px!important;
      background: #6b6c78;
      width: 14px;
      height: 14px;
      margin-left: 12px!important;

      &:after {
        width: 43px;
        height: 2px;
        //background: #ffffff;
        background: #6b6c78;
        content: ' ';
        display: block;
        transform: rotate(90deg);
        position: absolute;
        left: -2px;
        margin-top: 44px;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
    .swiper-pagination-bullet-active {
      background: #ffffff;
      width: 19px;
      height: 19px;
      margin-left: 10px!important;

      &:after {
        background: #6b6c78;
        opacity: 0.2;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }

  }

  &__slide {
    display: flex;

    @media (max-width: 768px) {
      display: block;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      margin-top: 20px;
    }

    &__left {
      //padding-left: 90px;

      @media (max-width: 480px) {
        padding-left: 0;
      }

      @media (max-width: 768px) {
        width: 100%!important;
      }

      &__title {
        margin-top: calc(30vh - 160px);
        font-size: 36px!important;
        line-height: 42px;
        font-family: "NotoSerif BoldItalic";

        @media (min-width: 769px) {
          font-size: 46px;
          line-height: 62px;
        }

        @media (min-height: 768px) {
          margin-top: 150px;
        }

        @media (min-width:1023px) {
          font-size: 46px;
          line-height: 62px;
        }
      }

      &__content {
        margin-top: 30px;
        font-size: 18px;
        line-height: 28px;
        @media (min-width: 769px) {
          font-size: 24px;
          line-height: 34px;
        }

        @media (min-width:1023px) {
          font-size: 24px;
          line-height: 34px;
        }
      }
    }
    
    &__right {

      @media (max-width: 768px) {
        width: 100%!important;
      }

      &__image {
        img {
          width: 100%;
          height: auto;
          margin-top: 80px;
          //max-height: 500px;

          @media (min-width: 425px) and (max-width: 768px) {
            margin-top: 130px;
          }
        }
      }      
    }
  }

  .swiper-container-autoheight,
  .swiper-container-autoheight .swiper-slide{
    @media (min-width: 481px) {
      max-height: calc(100vh - 150px);
    }
  }
}