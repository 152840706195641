.footer {
  h2 {
    margin-left: -15px;
    margin-bottom: 59px;

    @media (min-width: 320px) and (max-width: 768px) {
      padding: 0 15px;
    }
    @media (min-width: 768px) {
      font-size: 48px;
      line-height: 65px;

    }

  }

  &__text {
    color: #C9C9C9;
    font-family: "Lato Light";
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 30px;
  }

  &__logo {
    width: 230px;
    margin: 0 auto 50px auto;
    display: block;
    @media (min-width: 768px) {
      float: left;
      width: 270px;
    }
  }

  &__title, &__subtitle, &__text {
    @media (min-width: 768px) {
      text-align: left;
    }
  }

  &__kontakt {
    list-style: none;
    margin-bottom: 90px;
    padding-left: unset;
    text-align: center;
    @media (min-width: 768px) {
      text-align: left;
    }

    li {
      color: #FFFFFF;
      font-family: "Lato Light";
      font-size: 18px;
      line-height: 46px;
    }

    &__title {
      color: white;
      font-family: "Lato Bold";
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      @media (min-width: 768px) {
        text-align: left;
      }
    }

    li a {
      color: #C9C9C9;
    }
  }

  &__copyright {
    color: #FFFFFF;
    font-family: "Lato Light";
    font-size: 14px;
    line-height: 24px;
    text-align: center !important;
  }
}