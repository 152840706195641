.referenzen {
  h2 {
    margin-bottom: 70px;
  }
  .wp-block-gallery {
    .blocks-gallery-grid {
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;
      @media (min-width: 768px) {
        margin-bottom: 100px;

      }
      .blocks-gallery-item {
        margin-top: 40px;
        margin-right: 20px;
        @media (min-width: 426px) {
          flex-grow: 1 !important;
        }

        @media (max-width: 468px) {
          margin: auto auto 40px auto !important;
        }

        figure {
          padding-right: 15px;
          img {
            object-fit: cover;
            vertical-align: bottom;
          }
        }
      }
    }
  }
}