.ebook {
  margin-bottom: 30px;
  h2 {
    margin-bottom: 40px;
    margin-top: 65px;
    @media (max-width: 768px) {
      margin-top: 20px;

    }
    @media (min-width: 1025px) {
      margin-top: 140px;

    }
  }

  p {
    margin-bottom: 25px;
    @media (min-width: 768px) {
      text-align: left;
      margin-bottom: 30px;
    }
  }

  img {
    margin-bottom: 30px;
    width: 100%;
    @media (max-width: 768px) {
      margin-top: 70px;
    }

  }
  .ebook__download {
    background-color: #820038;
    box-shadow: 0 0 20px 1px rgba(130,0,56,0.4);
    color: white;
    display: block;
    border: none;
    width: 100%;
    height: 50px;
    font-family: "Lato Regular";
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    padding-top: 12px;

    &:hover {
      text-decoration: none;
    }
    margin-bottom: 70px;
    @media (min-width: 768px) {
      max-width: 75%;
      margin-top: 70px;
    }

  }

}