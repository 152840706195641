body {
  background: #171c2f;
}
h2 {
  color: white;
  font-family: "NotoSerif BoldItalic";
  font-size: 32px;
  line-height: 42px;
  font-style: italic;
  font-weight: bold;
  text-align: center;
  @media (min-width: 768px) {
    font-size: 36px;
    line-height: 49px;
    text-align: left;
  }
}

h3 {
  color: #FFFFFF;
  font-family: "Lato Bold";
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 20px;
  text-align: center;
  @media (min-width: 768px) {
    text-align: left;
  }
}

p {
  color: #FFFFFF;
  font-family: "Lato Light";
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  @media (min-width: 768px) {
    text-align: left;
  }
}

button {
  background-color: #820038;
  box-shadow: 0 0 15px 1px rgba(130,0,56,0.4);
  color: white;
  display: block;
  border: none;
  width: 100%;
  height: 50px;
  font-family: "Lato Regular";
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}

.button {
  background-color: #820038;
  box-shadow: 0 0 15px 1px rgba(130,0,56,0.4);
  color: white;
  display: block;
  border: none;
  width: 100%;
  height: 50px;
  font-family: "Lato Regular";
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}

@import "header";
@import "blocks";
@import "footer";
@import "fonts";
/*
  // Defined responsive media breakpoints
  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { }
*/