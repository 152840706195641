@font-face {
  font-family: "Lato Bold";
  src: url("../../assets/fonts/Lato-Bold.ttf");
}

@font-face {
  font-family: "Lato Light";
  src: url("../../assets/fonts/Lato-Light.ttf");
}

@font-face {
  font-family: "Lato Regular";
  src: url("../../assets/fonts/Lato-Regular.ttf") ;
}

@font-face {
  font-family: "NotoSerif BoldItalic";
  src: url("../../assets/fonts/NotoSerif-BoldItalic.ttf");
}