.contact {
  background: white;

  h2 {
    background: #fff;
    color: #171C2F !important;
    padding: 90px 20px 33px 0px;
  }

  p {
    font-family: "Lato Bold";
    color: #171C2F;
  }
  &__checkbox {
    position: relative;

    input {
      border-width: 7px;
      height: 24px;
      width: 12px;
      border-color: #78b13f;
    }


    input[type=checkbox] {
      vertical-align: bottom;
      position: relative;
      top: 27px;
      opacity: 0;
      display: inline-block;

      &:checked + label:after {
        content: '';
        display: block;
        width: 18px;
        height: 32px;
        border: solid #fff;
        border-width: 0px 5px 5px 0;
        transform: rotate(45deg);
        position: absolute;
        top: 23px;
        left: 12px;
      }

      &:checked + label:before {
        background: #820038;
        border: 2px solid #820038;
      }
    }

    label {
      color: #171C2F;
      font-family: "Lato Regular";
      display: block;
      font-size: 16px;
      line-height: 26px;
      padding-left: 50px;
      cursor: pointer;

      @media (min-width: 768px) {
        font-size: 18px;
        line-height: 40px;
        padding-left: 80px;
      }

      &:before {
        content: '';
        width: 30px;
        height: 30px;
        border: 2px solid #171C2F;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        display: block;
        position: absolute;
        left: 0px;
        top: 32px;
      }
    }
  }

  &__send {
    margin-top: 70px;

    button {
      width: 100%;

      @media (min-width: 768px) {
        width: 400px;
      }
    }

    &__success {
      margin-bottom: 100px;
    }

    &__false {
      margin-bottom: 35px;
      width: 100%;
    }
  }

  .form-group {
    label {
      color: #171C2F;
      font-family: "Lato Bold";
      font-size: 14px;
      line-height: 30px;
    }
    
    .success {
      border: 2px solid green;
      top: 0;
      padding: 15px;
      margin-bottom: 55px;
      font-size: 18px;
    }
  }

  .form-control {
    border: none;
    margin-bottom: 5px;
    border-bottom: 3px solid #171C2F;
    padding: unset;
    border-radius: unset;
    box-shadow: none !important;
    outline: none;
    padding-bottom: 20px;
    font-size: 18px;
  }

  input.invalid {
    border-bottom: 3px solid #820038;

    &::placeholder {
      color: #820038;
    }
  }

  .error-message{
    color: #820038!important;
  }
}