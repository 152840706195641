.header {
  &__logo {
    margin:45px 0;
    height: 68px;
    width: 155px;

    @media (min-width: 768px) {
      height: 94px;
      width: 215px;
    }
  }
}